<template>
  <div class="t" id="t"></div>
  <div class="vbox">
    <div class="scrollable" id="msgcontainer">
      <div class="hbox stretch">
        <div id="content">
          <div class="vbox-nouse">
            <div class="container container-h bg-white scrollable wrapper wrapper-xxs">
              <header>
                <span>Xin trịnh trọng tuyên bố: Trang web này chỉ dùng để thử nghiệm giao lưu. Không sử dụng cho mục đích bất hợp pháp. Chẳng hạn như cờ bạc, gian lận và các hoạt động bất hợp pháp khác! Nếu bạn có bất kỳ hành vi nào ở trên, vui lòng đăng xuất ngay lập tức.</span>
                <div :v-model="adsList" v-for="(item) in adsList" :key="item.id">
                  <img class="ads-top" :src="apiURL + item.avatar">
                </div>
              </header>

              <el-dialog
                  :title="title"
                  v-model="open"
                  width="380px"
                  :show-close="false"
                  :lock-scroll="true"
              >
                <div class="canvas-container">
                  <scratch-card
                      :key="scratchKey"
                      ref="scratchCard"
                      maskColor="#cccccc"
                      fillStyle="red"
                      font="30px 微软雅黑"
                      text="28Cào số trúng thưởng"
                      :imageUrl="imageUrl"
                      :radius="5"
                      :scratchRadius="20"
                      @scratchStart="scratchStart"
                      @scratchEnd="scratchEnd"
                      @scratchAll="scratchAll"
                  >
                    <div class="ggtitri fr">Thứ <span class="ggcolor curSue">{{ drawNumber }}</span> Kỳ Kết quả mở
                      thưởng
                    </div>
                    <div class="prize">
                      <div class="ggcon">
                        <span class="gggbor gggbor1 code1">{{ first }}</span>
                        <span>+</span>
                        <span class="gggbor code2"> {{ second }}</span>
                        <span>+</span>
                        <span class="gggbor code3"> {{ third }}</span>
                        <span>= </span>
                        <span class="gggr1 gggbor2 curSum"> {{ sumNumber }}</span>
                        <span class='gggr curDxds' v-if="bigSmall === 'Tài'" :v-model="bigSmall">
                      {{ bigSmall }}
                    </span>
                        <span class='gggr curDxds' v-if="bigSmall === 'Xỉu'" :v-model="bigSmall">
                      {{ bigSmall }}
                    </span>
                        <span class='gggr curDxds' v-if="oddEven === 'Lẻ'" :v-model="oddEven">
                      {{ oddEven }}
                    </span>
                        <span class='gggr curDxds' v-if="oddEven === 'Chẵn'" :v-model="oddEven">
                      {{ oddEven }}
                    </span>
                      </div>
                    </div>
                  </scratch-card>
                </div>
                <template #footer>
                  <div class="dialog-footer">
                    <el-button type="primary" @click="resetDrawCode">Làm mới</el-button>
                    <el-button @click="closeDrawCode">Đóng</el-button>
                  </div>
                </template>
              </el-dialog>

              <div class="room">
                <div class="bg-white lot-type clearfix">
                  <a class="active" href="">Canada28</a>
                </div>
                <div class="lot-m clearfix">
                  <div class="col-md-4 col-sm-6 lot-latest text-center">
                    <div class="clearfix qyTab" style="display:inline-block;">
                      <div class="pull-left t-logo">
                        <img src="../assets/image/canada28.jpg">
                      </div>
                      <div class="lot-section pull-left">
                        <p class="qy_name">Canada28</p>
                        <p>
                          Mới nhất：
                          <i
                              id="period"
                              class=""
                              ng-hide="loadingLatest"
                              :v-model="drawNumber">
                            {{ drawNumber }}
                          </i>
                          Kỳ
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style="min-height: 158px;" class="col-md-4 col-sm-7 open-cutdown no-padder">
                    <div class="next-draw-number-text">Kỳ tiếp theo:
                      <span
                          class="next-draw-number-num"
                          :v-model="nextDrawNumber">{{ nextDrawNumber }}
                    </span>
                      <a href="" @click="refresh"> Làm mới</a>
                    </div>
                    <el-button class="ggl" @click="openDrawCode()">Hình thức cào số trúng thưởng</el-button>
                    <div id="out_time" class="opentime ng-isolate-scope" style="display: inline-block;">
                      <van-col>
                        <van-count-down
                            :time="countTime"
                            :v-model="countTime"
                            format="mm:ss"
                            @finish="onFinish"
                        >
                          <template #default="timeData">
                            <b class="ng-binding" id="minutes" v-if="countTime > 0">{{ timeData.minutes }}</b>
                            <b class="ng-binding" id="minutes" v-else>--</b>
                            <b class="ng-binding">:</b>
                            <b class="ng-binding" id="seconds" v-if="countTime > 0">{{ timeData.seconds }}</b>
                            <b class="ng-binding" id="seconds" v-else>--</b>
                          </template>
                        </van-count-down>
                      </van-col>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 lot-result">
                    <div class="r1" data-state="0" :v-model="countTime" v-if="countTime > 0">
                      <span class="allBall" :v-model="first">{{ first }}</span>
                      <label class="">+</label> <span class="allBall" :v-model="second">{{ second }}</span>
                      <label class="">+</label> <span class="allBall" :v-model="third">{{ third }}</span>
                      <label class="">=</label>
                      <span class="sumBall">{{ sumNumber }}</span>
                    </div>
                    <div class="r2" :v-model="countTime" v-if="countTime > 0">
                    <span class='badge red-info red-info' v-if="bigSmall === 'Tài'" :v-model="bigSmall">
                      {{ bigSmall }}
                    </span>
                      <span class='badge blue-info blue-info' v-if="bigSmall === 'Xỉu'" :v-model="bigSmall">
                      {{ bigSmall }}
                    </span>
                      <span class='badge blue-info blue-info' v-if="oddEven === 'Lẻ'" :v-model="oddEven">
                      {{ oddEven }}
                    </span>
                      <span class='badge red-info red-info' v-if="oddEven === 'Chẵn'" :v-model="oddEven">
                      {{ oddEven }}
                    </span>
                    </div>
                    <div class="r3" v-else>
                      Chờ đợi kết quả
                      <img src="../assets/image/icon-status-status-dots-anim-16.gif">
                    </div>
                  </div>
                </div>
              </div>

              <div class="panelTable">
                <div class="demo-tabs">
                  <i class="el-arrow-left" style="font-size: 48px"></i>
                </div>
                <el-tabs
                    class="demo-tabs"
                    type="border-card"
                    stretch
                    v-model="activeTabName"
                    @tab-click="handleTabClick"
                >
                  <el-tab-pane name="result">
                    <template #label>
                    <span class="custom-tabs-label">
                      <img src="../assets/image/tab2_01.png">
                      <span>Kết quả</span>
                    </span>
                    </template>
                    <el-table v-model="list" class="tableBox" stripe :data="list">
                      <el-table-column
                          label="Số kỳ"
                          align="center"
                          prop="drawNumber"
                      />
                      <el-table-column
                          label="Thời gian"
                          align="center"
                          prop="drawDate"
                      />
                      <el-table-column
                          label="Số"
                          align="center"
                          prop="drawCode"
                          min-width="88px"
                      />
                      <el-table-column
                          label="Đôi Sảnh Báo"
                          align="center"
                          prop="pairsJunkoLeopard"
                      />
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="Config" name="trend">
                    <template #label>
                    <span class="custom-tabs-label">
                      <img src="../assets/image/tab2_04.png">
                      <span>Xu hướng</span>
                    </span>
                    </template>
                    <el-table class="tableBox" stripe :data="trendList">
                      <el-table-column
                          label="Số kỳ"
                          align="center"
                          prop="drawNumber"
                      />
                      <el-table-column
                          label="Tổng hợp"
                          align="center"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.drawNumber !== 'Khoảng cách'">{{ scope.row.sum }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Tài"
                          align="center"
                      >
                        <template #default="scope">
                          <div class="r2">
                            <span v-if="scope.row.drawNumber === 'Khoảng cách'">{{ scope.row.big }}</span>
                            <span class="badge red-info red-info" v-else-if="scope.row.big === 1">Tài</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Xỉu"
                          align="center"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.drawNumber === 'Khoảng cách'">{{ scope.row.small }}</span>
                          <span v-else-if="scope.row.small === 1" class="badge blue-info blue-info">Xỉu</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Lẻ"
                          align="center"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.drawNumber === 'Khoảng cách'">{{ scope.row.odd }}</span>
                          <span v-else-if="scope.row.odd === 1" class="badge blue-info blue-info">Lẻ</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Chẵn"
                          align="center"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.drawNumber === 'Khoảng cách'">{{ scope.row.even }}</span>
                          <span v-else-if="scope.row.even === 1" class="badge red-info red-info">Chẵn</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Tài lẻ"
                          align="center"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.drawNumber === 'Khoảng cách'">{{ scope.row.bigOdd }}</span>
                          <span v-else-if="scope.row.bigOdd === 1" class="badge blue-info blue-info">Tài lẻ</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Tài chẵn"
                          align="center"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.drawNumber === 'Khoảng cách'">{{ scope.row.bigEven }}</span>
                          <span v-else-if="scope.row.bigEven === 1" class="badge red-info red-info">Tài chẵn</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Xỉu lẻ"
                          align="center"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.drawNumber === 'Khoảng cách'">{{ scope.row.smallOdd }}</span>
                          <span v-else-if="scope.row.smallOdd === 1" class="badge blue-info blue-info">Xỉu lẻ</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Xỉu chẵn"
                          align="center"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.drawNumber === 'Khoảng cách'">{{ scope.row.smallEven }}</span>
                          <span v-else-if="scope.row.smallEven === 1" class="badge red-info red-info">Xỉu chẵn</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="Total" name="total">
                    <template #label>
                      <span class="custom-tabs-label">
                        <img src="../assets/image/tab2_07.png">
                        <span>Thống kê</span>
                      </span>
                    </template>
                    <el-card class="box-card">
                      <template #header>
                        <div class="card-header">
                          <span>Chưa mở Lệnh đôi</span>
                        </div>
                      </template>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-form-item label="Tài lẻ" prop="totalInfo.bigOdd">
                            <el-tag
                                class="ml-12"
                                style="margin-left: 18px"
                                v-model="totalInfo.bigOdd"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.bigOdd }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Xỉu lẻ" prop="totalInfo.smallOdd">
                            <el-tag
                                class="ml-12"
                                style="margin-left: 18px"
                                v-model="totalInfo.smallOdd"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.smallOdd }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Tài chẵn" prop="totalInfo.bigEven">
                            <el-tag
                                class="ml-12"
                                v-model="totalInfo.bigEven"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.bigEven }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Xỉu chẵn" prop="totalInfo.smallEven">
                            <el-tag
                                class="ml-12"
                                v-model="totalInfo.smallEven"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.smallEven }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card class="box-card">
                      <template #header>
                        <div class="card-header">
                          <span>Chưa mở Lớn nhất Nhỏ nhất</span>
                        </div>
                      </template>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-form-item label="Lớn  nhất" prop="totalInfo.huge">
                            <el-tag
                                class="ml-12"
                                v-model="totalInfo.huge"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.huge }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Nhỏ nhất" prop="totalInfo.tiny">
                            <el-tag
                                class="ml-12"
                                v-model="totalInfo.tiny"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.tiny }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card class="box-card">
                      <template #header>
                        <div class="Chưa mở card-header">
                          <span>Chưa mở Đôi Sảnh Báo</span>
                        </div>
                      </template>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-form-item label="Sảnh" prop="totalInfo.junko">
                            <el-tag
                                class="ml-12"
                                v-model="totalInfo.junko"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.junko }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Đôi" prop="totalInfo.pairs">
                            <el-tag
                                class="ml-12"
                                v-model="totalInfo.pairs"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.pairs }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Báo" prop="totalInfo.leopard">
                            <el-tag
                                class="ml-12"
                                style="margin-left: 10px"
                                v-model="totalInfo.leopard"
                                color="#fff"
                                size="large"
                            >{{ totalInfo.leopard }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card class="box-card">
                      <template #header>
                        <div class="card-header">
                          <span>Chưa mở Con số</span>
                        </div>
                      </template>
                      <div v-for="(item, index) in totalInfo.number" :key="index">
                        <el-row :gutter="24" v-if="index % 2 == 0">
                          <el-col :span="12">
                            <el-form-item :label="item.key">
                              <el-tag
                                  class="ml-12"
                                  v-model="item.value"
                                  color="#fff"
                                  size="large"
                              >{{ item.value }}
                              </el-tag>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item :label="index + 1">
                              <el-tag
                                  class="ml-12"
                                  v-model="totalInfo.number[index+1].value"
                                  color="#fff"
                                  size="large"
                              >{{ totalInfo.number[index + 1].value }}
                              </el-tag>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </div>
                    </el-card>
                  </el-tab-pane>
                  <el-tab-pane label="Today" name="today">
                    <template #label>
                      <span class="custom-tabs-label">
                        <img src="../assets/image/tab2_07.png">
                        <span>Hôm nay</span>
                      </span>
                    </template>
                    <span class="today-title">
                      Thống kê kết quả mở thưởng mỗi ngày từ 00:00 đến 23:59
                    </span>
                    <el-card class="box-card">
                      <template #header>
                        <div class="card-header">
                          <span>Đã mở Tài xỉu lẻ chẵn</span>
                        </div>
                      </template>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-form-item label="Tài" prop="today.big">
                            <el-tag
                                class="ml-12"
                                v-model="today.big"
                                color="#fff"
                                size="large"
                            >{{ today.big }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Xỉu" prop="today.small">
                            <el-tag
                                class="ml-12"
                                style="margin-left: 12px"
                                v-model="today.small"
                                color="#fff"
                                size="large"
                            >{{ today.small }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Lẻ" prop="today.odd">
                            <el-tag
                                class="ml-12"
                                style="margin-left: 5px"
                                v-model="today.odd"
                                color="#fff"
                                size="large"
                            >{{ today.odd }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Chẵn" prop="today.even">
                            <el-tag
                                class="ml-12"
                                v-model="today.even"
                                color="#fff"
                                size="large"
                            >{{ today.even }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card class="box-card">
                      <template #header>
                        <div class="card-header">
                          <span>Đã mở Lệnh đôi</span>
                        </div>
                      </template>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-form-item label="Tài lẻ" prop="today.bigOdd">
                            <el-tag
                                class="ml-12"
                                style="margin-left: 16px"
                                v-model="today.bigOdd"
                                color="#fff"
                                size="large"
                            >{{ today.bigOdd }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Xỉu lẻ" prop="today.smallOdd">
                            <el-tag
                                class="ml-12"
                                style="margin-left: 18px"
                                v-model="today.smallOdd"
                                color="#fff"
                                size="large"
                            >{{ today.smallOdd }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Tài chẵn" prop="today.bigEven">
                            <el-tag
                                class="ml-12"
                                v-model="today.bigEven"
                                color="#fff"
                                size="large"
                            >{{ today.bigEven }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Xỉu chẵn" prop="today.smallEven">
                            <el-tag
                                class="ml-12"
                                v-model="today.smallEven"
                                color="#fff"
                                size="large"
                            >{{ today.smallEven }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card class="box-card">
                      <template #header>
                        <div class="card-header">
                          <span>Đã mở Lớn nhất Nhỏ nhất</span>
                        </div>
                      </template>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-form-item label="Lớn  nhất" prop="today.huge">
                            <el-tag
                                class="ml-12"
                                v-model="today.huge"
                                color="#fff"
                                size="large"
                            >{{ today.huge }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Nhỏ nhất" prop="today.tiny">
                            <el-tag
                                class="ml-12"
                                v-model="today.tiny"
                                color="#fff"
                                size="large"
                            >{{ today.tiny }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card class="box-card">
                      <template #header>
                        <div class="card-header">
                          <span>Đã mở Đôi Sảnh Báo</span>
                        </div>
                      </template>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-form-item label="Sảnh" prop="today.junko">
                            <el-tag
                                class="ml-12"
                                v-model="today.junko"
                                color="#fff"
                                size="large"
                            >{{ today.junko }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Đôi" prop="today.pairs">
                            <el-tag
                                class="ml-12"
                                v-model="today.pairs"
                                color="#fff"
                                size="large"
                            >{{ today.pairs }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Báo" prop="today.leopard">
                            <el-tag
                                class="ml-12"
                                style="margin-left: 8px"
                                v-model="today.leopard"
                                color="#fff"
                                size="large"
                            >{{ today.leopard }}
                            </el-tag>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                    <el-card class="box-card">
                      <template #header>
                        <div class="card-header">
                          <span>Đã mở Con số</span>
                        </div>
                      </template>
                      <div v-for="(item, index) in today.number" :key="index">
                        <el-row :gutter="24" v-if="index % 2 == 0">
                          <el-col :span="12">
                            <el-form-item :label="item.key">
                              <el-tag
                                  class="ml-12"
                                  style="margin-left: 8px"
                                  v-model="item.value"
                                  color="#fff"
                                  size="large"
                              >{{ item.value }}
                              </el-tag>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item :label="index + 1">
                              <el-tag
                                  class="ml-12"
                                  style="margin-left: 8px"
                                  v-model="today.number[index+1].value"
                                  color="#fff"
                                  size="large"
                              >{{ today.number[index + 1].value }}
                              </el-tag>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </div>
                    </el-card>
                  </el-tab-pane>
                  <el-tab-pane label="forecast" name="forecast">
                    <template #label>
                      <span class="custom-tabs-label">
                        <img src="../assets/image/tab2_07.png">
                          <span>Dự đoán</span>
                      </span>
                    </template>
                    <el-table class="tableBox" stripe :data="forecastList" :show-header="false">
                      <el-table-column
                          label="Số kỳ"
                          align="center"
                          min-width="88px"
                          prop="drawNumber"
                      />
                      <el-table-column
                          label="Số"
                          align="center"
                          prop="drawCode"
                          min-width="88px"
                          :formatter="formatterDrawCode"
                      />
                      <el-table-column
                          label="Hai mặt"
                          align="right"
                          min-width="48px"
                      >
                        <template #default="scope">
                          <span class="win"
                                v-if="formatterBigSmallClass(scope.row)">{{ formatterBigSmall(scope.row) }}</span>
                          <span class="lose" v-else>{{ formatterBigSmall(scope.row) }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Hai mặt"
                          align="left"
                          min-width="52px"
                      >
                        <template #default="scope">
                          <span class="win"
                                v-if="formatterOddEvenClass(scope.row)">{{ formatterOddEven(scope.row) }}
                          </span>
                          <span class="lose" v-else>{{ formatterOddEven(scope.row) }}
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="Kết quả dự đoán"
                          align="center"
                          prop="forecast"
                          min-width="32px"
                      >
                        <template #default="scope">
                          <span v-if="scope.row.forecast == '0' && scope.row.drawCode != ''">
                            <img src='../assets/image/dc_0.png'>
                          </span>
                          <span v-if="scope.row.forecast == '1' && scope.row.drawCode != ''">
                            <img src='../assets/image/dc_1.png'>
                          </span>
                          <span v-else></span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                </el-tabs>
                <div>
                  <i class="el-icon-arrow-right" style="font-size: 48px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DrawList, Forecast, GetInfo, Today, Total, Trend} from "@/api/draw";
import ScratchCard from "@/components/ScratchCard";
import apiUrl from "@/config/api";
import {AdsList} from "@/api/ads";

export default {
  name: 'CanadaTwoEight',
  props: {
    msg: String
  },
  components: {
    ScratchCard,
  },
  data() {
    return {
      adsList: [],
      apiURL: apiUrl,
      scratchKey: Date.now(),
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        id: undefined,
        alias: undefined,
        name: undefined,
        status: undefined,
      },
      nextDrawTime: "",
      nextKenoDrawTime: "",
      bigSmall: '',
      oddEven: '',
      first: '',
      second: '',
      third: '',
      drawNumber: '',
      sumNumber: '',
      nextDrawNumber: '',
      activeTabName: '',
      statusOptions: [],
      dateRange: [],
      loading: true,
      total: 0,       // 总条数
      list: [],       // 列表数组
      forecastList: [],
      form: {},
      title: "Canada28 Cào số trúng thưởng",
      open: false,
      today: {
        big: 0,
        small: 0,
        odd: 0,
        even: 0,
        bigOdd: 0,
        smallOdd: 0,
        bigEven: 0,
        smallEven: 0,
        huge: 0,
        tiny: 0,
        leopard: 0,
        junko: 0,
        pairs: 0,
        number: [],
      },
      trendList: [],
      totalInfo: {
        bigOdd: 0,
        smallOdd: 0,
        bigEven: 0,
        smallEven: 0,
        huge: 0,
        tiny: 0,
        leopard: 0,
        junko: 0,
        pairs: 0,
        number: [],
        openCountDown: {},
      },
      responseData: null,
      timerId: null,
      countTime: 0,
      isDrawing: false, // 正在绘制中的标识
      lastPoint: null,  // 记录点的位置
      canvas: null,     // canvas
      ctx: null,        // context
      brush: null,      // brush
      canvasShow: true, // canvas是否显示
      dataShow: false,   // 内容是否显示，默认不显示
      imageUrl: require(`@/assets/image/p_0.png`),
      slot: {
        offsetHeight: 300,
        offsetWidth: 100,
      }
    }
  },
  created() {
    this.countTime = 0;
    this.timerId = null;
    this.responseData = null;
    this.getAdsList();
    this.getList();
  },
  mounted() {
    this.activeTabName = 'result';
  },
  onBeforeUnmount() {
    clearTimeout(this.timerId);
  },
  beforeUnmount() {
    clearTimeout(this.timerId);
  },
  unmounted() {
    clearTimeout(this.timerId);
  },
  methods: {
    scratchStart() {
    },
    scratchEnd() {
    },
    scratchAll() {
    },
    refresh() {
      this.getList();
    },
    openDrawCode() {
      this.open = true;
    },
    resetDrawCode() {
      this.scratchKey = Date.now();
    },
    closeDrawCode() {
      this.open = false;
      this.scratchKey = Date.now();
    },
    setCountTime() {
      const currentTime = Date.now();
      this.countTime = this.nextDrawTime - currentTime;
    },
    formatterOddEven(row) {
      const odd = row.odd;
      const even = row.even;
      if (odd == 1) {
        return "Lẻ";
      }
      if (even == 1) {
        return "Chẵn";
      }
      return "";
    },
    onFinish() {
      this.countTime = 0;
      this.getInfo();
    },
    async getInfo() {
      if (this.countTime === 0) {
        await GetInfo({'drawNumber': this.nextDrawNumber}).then((res) => {
          if (res.data != null) {
            clearTimeout(this.timerId);
            this.getList();
            this.handleList(this.activeTabName);
          } else {
            this.timerId = setTimeout(this.getInfo, 3000)
          }
        }).catch(error => {
          console.error('Error get data:', error);
        });
      }
    },
    formatterDrawCode(row) {
      if (row.drawCode === '') {
        return 'chỉ để tham khảo';
      }
      return row.drawCode;
    },
    formatterOddEvenClass(row) {
      if (row.drawCode == '') {
        return true
      }
      const odd = row.odd;
      const even = row.even;
      const forecast = row.forecast;
      const drawCode = row.drawCode.split('=');
      if (drawCode.length > 0) {
        const code = drawCode[drawCode.length - 1];
        if (forecast === 1) {
          const isOdd = code % 2 == 0 ? false : true;
          const isEven = code % 2 == 0 ? true : false;
          if (odd === 1 && isOdd) {
            return true;
          }
          if (even === 1 && isEven) {
            return true;
          }
        }
      }
      return false;
    },
    formatterBigSmallClass(row) {
      const big = row.big;
      const small = row.small;
      const forecast = row.forecast;
      if (row.drawCode == '') {
        return true
      }
      const drawCode = row.drawCode.split('=');
      if (drawCode.length > 0) {
        const code = drawCode[drawCode.length - 1];
        if (forecast === 1) {
          const isBig = code >= 14 && code <= 27 ? true : false;
          const isSmall = code >= 0 && code <= 13 ? true : false;
          if (big === 1 && isBig) {
            return true;
          }
          if (small === 1 && isSmall) {
            return true;
          }
        }
      }
      return false;
    },
    formatterBigSmall(row) {
      const big = row.big;
      const small = row.small;
      if (big == 1) {
        return "Tài";
      }
      if (small == 1) {
        return "Xỉu";
      }
      return "";
    },
    handleTabClick(tab) {
      if (tab.props.name === 'result') {
        return this.getList();
      }
      if (tab.props.name === 'trend') {
        return this.getTrend();
      }
      if (tab.props.name === 'total') {
        return this.getTotalInfo();
      }
      if (tab.props.name === 'today') {
        return this.getToday();
      }
      if (tab.props.name === 'forecast') {
        return this.getForecast();
      }
    },
    handleList(activeTabName) {
      if (activeTabName === 'trend') {
        return this.getTrend();
      }
      if (activeTabName === 'total') {
        return this.getTotalInfo();
      }
      if (activeTabName === 'today') {
        return this.getToday();
      }
      if (activeTabName === 'forecast') {
        return this.getForecast();
      }
    },
    getAdsList() {
      this.loading = true;
      AdsList(this.queryParams).then((res) => {
        let data = res.data;
        this.adsList = data.list;
        this.loading = false;
      });
    },
    getList() {
      this.loading = true;
      DrawList(this.queryParams).then((res) => {
        let data = res.data;
        this.list = data.list;
        this.total = data.total;
        this.drawNumber = this.list[0].drawNumber;
        this.nextDrawNumber = parseInt(this.list[0].drawNumber) + 1;
        const drawCode = this.list[0].drawCode;
        const code = drawCode.split('=');
        this.sumNumber = code[code.length - 1];
        this.first = code[0].split('+')[0];
        this.second = code[0].split('+')[1];
        this.third = code[0].split('+')[2];
        this.isBigSmall(this.sumNumber);
        this.isOddEven(this.sumNumber);
        this.nextDrawTime = this.list[0].nextDrawTime;
        this.nextKenoDrawTime = this.list[0].nextKenoDrawTime;
        this.setCountTime();
        this.loading = false;
      });
    },
    isBigSmall(sum) {
      this.bigSmall = sum >= 0 && sum <= 13 ? 'Xỉu' : sum >= 14 && sum <= 27 ? 'Tài' : '';
    },
    isOddEven(sum) {
      this.oddEven = sum % 2 === 0 ? 'Chẵn' : 'Lẻ';
    },
    getForecast() {
      this.loading = true;
      Forecast(this.queryParams).then((res) => {
        let data = res.data;
        this.forecastList = data.list;
        this.total = data.total;
        this.loading = false;
      });
    },
    getToday() {
      this.loading = true;
      Today().then((res) => {
        this.today = res.data;
        this.loading = false;
      });
    },
    getTrend() {
      this.loading = true;
      Trend(this.queryParams).then((res) => {
        this.trendList = res.data;
        this.loading = false;
      });
    },
    getTotalInfo() {
      this.loading = true;
      Total().then((res) => {
        this.totalInfo = res.data;
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #428bca;
}

.today-number {
}

.today-number >>> input:disabled {
  color: #428bca !important;
}

.demo-tabs {
  --el-tabs-header-height: 48px;
}

.demo-tabs >>> .el-icon {
  margin: 8px auto;
  line-height: 48px;
  font-size: 32px;
  width: 28px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.tableBox {
  width: auto;
}

.tableBox .th {
  padding: 0 !important;
  height: auto;
  line-height: 48px;
}

.tableBox .td {
  padding: 0 !important;
  height: auto;
  line-height: 48px;
}

.win {
  color: #b52013;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  width: 16px;
}

.lose {
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  width: 16px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-card {
  width: 100%;
  margin-top: 9px;
  margin-bottom: 9px;
}

.today-title {
  color: #b52013;
  margin-top: 9px;
  margin-bottom: 9px;
  font-size: 16px;
  height: 16px;
  line-height: 22px;
  text-align: center;
  width: 22px;
}

.allBall {
  color: #fff;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #13b5b1;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  text-align: center;
}

.sumBall {
  color: #fff;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #556fb5;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  text-align: center;
}

.canvas-container {
  color: #000;
  margin: 0 auto;
  width: 320px;
  height: 294px;
  background: url(../assets/image/gglbj.png) no-repeat center center;
  background-size: cover;
  padding: 10px;
  position: relative;
  font-size: 17px;
}

.prize {
  -moz-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  -khtml-user-select:none;
  user-select:none;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 80%;
  bottom: 0;
  transform: translate(-50%, -50%);
}

.next-draw-number-text {
  text-align: left;
  min-width: 237px;
  min-height: 58px;
  margin: 0 auto;
  font-size: 21px;
  color: #333;
  text-indent: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-draw-number-num {
  margin-left: 3px;
  color: #fe474b;
  padding-left: 3px;
}

.ads-top {
  width: 100%
}

.ggl {
  color: #fff;
  border-radius: 5px;
  min-width: 168px;
  background: url(../assets/image/btn2.jpg) 0 0 repeat-x;
  background-size: cover;
  height: 32px;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ggl:hover {
  color: #fff;
  text-decoration: none;
}

.today-el-form-item {
  width: 100%;
}

.today-el-form-item /deep/ input:disabled {
  font-size: 18px;
  color: red;
  background-color: #fff;
  width: 100%;
}

.today-el-input input:disabled /deep/ .el-input__inner {
  color: red;
}

.cell-space /deep/ .el-table .el-table__body-wrapper .el-table__body {
  border-collapse: separate;
  border-spacing: 5px;
}
</style>
